import React from "react";
import { useState } from "react";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { BaseUrl } from "../Constant/BaseUrl";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let body = JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        message: message,
        type: "CONTACT",
      });
      const response = await axios.post(`${BaseUrl}submit-contact`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      document.getElementById("contact-form").reset();
      toast.success(response.data.msg);
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <WebLayout>
      <>
        <main className="fix">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">Contact With Us</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Contact
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="contact__area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="contact__content">
                    <div className="section-title mb-35">
                      <h2 className="title">How can we help you?</h2>
                    </div>
                    <div className="contact__info">
                      <ul className="list-wrap">
                        <li>
                          <div className="icon">
                            <i className="flaticon-phone-call"></i>
                          </div>
                          <div className="content">
                            <h4 className="title">Phone</h4>
                            <Link to="tel:9971713967">+91-9971713967</Link>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="flaticon-mail"></i>
                          </div>
                          <div className="content">
                            <h4 className="title">E-mail</h4>
                            <Link to="mailto:care@abhhyamsecure.com">
                              care@abhhyamsecure.com
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="contact__form-wrap">
                    <h2 className="title">Give Us a Message</h2>
                    <p>
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <form
                      id="contact-form"
                      onSubmit={handleSubmit}
                      method="POST"
                    >
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-grp">
                            <input
                              type="text"
                              name="name"
                              placeholder="Name *"
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-grp">
                            <input
                              type="email"
                              name="email"
                              placeholder="Email *"
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-grp">
                            <input
                              type="number"
                              name="phone"
                              placeholder="Phone *"
                              onChange={(e) => setPhone(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className={"col-md-12"}>
                          <div className="form-grp">
                            <textarea
                              name="message"
                              placeholder="Message *"
                              onChange={(e) => setMessage(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="registerBtn">
                        SUBMIT
                      </button>
                    </form>
                    <p className="ajax-response mb-0"></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    </WebLayout>
  );
}

export default Contact;
