import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BaseUrl, LOGO } from "../Constant/BaseUrl";
import axios from "axios";

function Footer() {
  const [policy, setPolicy] = useState([]);

  const getPolicy = async () => {
    try {
      const policyResponse = await axios.get(`${BaseUrl}category/POLICY`);
      setPolicy(policyResponse.data.data || []);
    } catch (error) {}
  };

  useEffect(() => {
    getPolicy();
  }, []);

  return (
    <footer>
      <div className="footer-area">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-logo mb-25">
                    <Link to="#">
                      <img src={LOGO} alt="Logo" />
                    </Link>
                  </div>
                  <div className="footer-content">
                    <p>
                      ASL (Abhhyam Secured Life) is an innovative and holistic
                      approach to employee welfare and organizational stability.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Information</h4>
                  <div className="footer-info-list">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="content">
                          <Link to="tel:9971713967">+91-9971713967</Link>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-envelope"></i>
                        </div>
                        <div className="content">
                          <Link to="mailto:care@abhhyamsecure.com">
                            care@abhhyamsecure.com
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Consumer Policy</h4>
                  <div className="footer-link-list">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/about">About ASL</Link>
                      </li>

                      {policy.map((item, index) => (
                        <li key={index}>
                          <Link to={`/policy/${item.slug}`}>{item.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Quick Links</h4>
                  <div className="footer-link-list">
                    <ul className="list-wrap">
                      <li>
                        <Link
                          to="/erm-registration"
                          style={{ color: "#f7a400", fontWeight: "bold" }}
                        >
                          Representative (ERM) Registration
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://erm.abhhyamsecure.com/"
                          target="_blank"
                          style={{ color: "#f7a400", fontWeight: "bold" }}
                        >
                          Representative (ERM) Login
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/emc-registration"
                          style={{ color: "#f7a400", fontWeight: "bold" }}
                        >
                          Company (EMC) Registration
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://emc.abhhyamsecure.com/"
                          target="_blank"
                          style={{ color: "#f7a400", fontWeight: "bold" }}
                        >
                          Company (EMC) Login
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text text-center">
                  <p style={{ fontWeight: "bold" }}>
                    Copyright ©{" "}
                    <Link
                      to="https://abhhyamsecure.com"
                      style={{ fontWeight: "bold" }}
                    >
                      Abhhyamsecure.com
                    </Link>{" "}
                    | All Right Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-shape">
          <img
            src={"assets/img/images/footer_shape01.png"}
            alt=""
            data-aos="fade-right"
            data-aos-delay="400"
          />
          <img
            src={"assets/img/images/footer_shape02.png"}
            alt=""
            data-aos="fade-left"
            data-aos-delay="400"
          />
          <img
            src={"assets/img/images/footer_shape03.png"}
            alt=""
            data-parallax='{"x" : 100 , "y" : -100 }'
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
