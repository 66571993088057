import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../Constant/BaseUrl";
import toast from "react-hot-toast";

const SearchSelect = ({
  setGuideId,
  options,
  setOptions,
  query,
  setQuery,
  onPress,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (query.length > 3) {
      setLoading(true);
      axios
        .post(`${BaseUrl}guide-list`, JSON.stringify({ search: query }), {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          setOptions(response.data.data);
        })
        .catch((error) => {
          toast.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setOptions([]);
    }
  }, [query, setOptions]);

  const handleOptionSelect = (option) => {
    setQuery(option.label);
    setGuideId(option.value);
    setOptions([]);
  };

  const clearInput = () => {
    setQuery("");
    setOptions([]);
    setGuideId("");
  };

  return (
    <div className="relative">
      <div className="form-grp" style={{ position: "relative" }}>
        <input
          type={"text"}
          name={"name"}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={"ASL Rep. ERM No."}
          style={{
            background: "#FFFFFF",
            fontWeight: 500,
            paddingRight: "30px",
          }}
          className={"textPlaceholder"}
          autoComplete={"off"}
          onClick={onPress}
        />
        {query && (
          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
            onClick={clearInput}
          >
            <i className={"fa fa-window-close"} style={{ color: "red" }} />
          </div>
        )}
      </div>

      {loading && <p className="absolute top-12 left-4">Loading...</p>}

      {query && options.length > 0 && (
        <div className="absolute w-full mt-1 optionsBg border z-10">
          <ul
            className="overflow-y-auto style-option list-unstyled m-0"
            id={"style-option"}
            style={{ height: 150 }}
          >
            {options.map((option, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-200 optionsText"
                onClick={() => handleOptionSelect(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchSelect;
